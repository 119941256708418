import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authguard/auth.guard';

const routes: Routes = [

  // {
  //   path: "",
  //   loadChildren: () => import('./all-module/all-module.module').then(m => m.AllModuleModule)
  // },
  {
    path: "login",
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    //data: { animation: 'isRight' }

  },
  {
    path: "login/:companyId/:companyName",
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    // data: { animation: 'isRight' }

  },
  {
    path: "login/:companyId/:companyName/:userId",
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    // data: { animation: 'isRight' }

  },
  {
    path: "registerCompany",
    loadChildren: () => import('./registercompany/registercompany.module').then(m => m.RegistercompanyModule),
    canActivate: [AuthGuard],
    // data: { animation: 'isRight' }

  },
  {
    path: "",
    loadChildren: () => import('./all-module/all-module.module').then(m => m.AllModuleModule),
    canActivate: [AuthGuard],
    //data: { animation: 'isRight' }

  },
  {
    path: "forgotPassword",
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule),
    // data: { animation: 'isRight' }

  },
  {
    path: "register",
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
    // data: { animation: 'isRight' }

  },
  {
    path: "externallink/:companyId/:loadLabel",
    loadChildren: () => import('./externalmap/externalmap.module').then(m => m.ExternalmapModule)
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
