export const environment = {
    production: true,
    APIURL: 'http://44.240.165.248:8081',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG9hZG9zIiwiYSI6ImNsMzR1OHJoZjAxajAza3JmODVuamN1YXIifQ._bf0ccb8f5M2VKt4gG8ONQ'
    },
    chatURL: "http://35.162.154.188:5280/bosh/",
    EXTERNALMAPLINK: "http://44.240.165.248/cargoHQ",
    aws: {
        accessKeyId: 'AKIAU6GDVH2LQS7LKZET',
        secretAccessKey: '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',
        region: 'us-west-2',
        bucket: 'cargohq-dev-s3'
    },
    chargeBeeHostedPage: "https://cargohq-test.chargebee.com/api/v2/hosted_pages/checkout_existing_for_items",
    chargeBeeAPIKey: "Basic test_YYmwvvpVFkOOuDOINIPYcufwRmdWYf5iw"
};
//DEV APIURL: 'http://52.42.222.132:8081',