import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urlconstants } from '../common/urlconstant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  doLogin(user: any) {
    var userSignInRequest = {
      email: user.email,
      password: user.password
    }
    return this.http.post(Urlconstants.auth.login, userSignInRequest);
  }
  doLoadOSAdminLogin(user: any, companyId: any, userId: any) {
    var userSignInRequest = {
      companyId: companyId,
      userId: userId === undefined ? null : userId,
      email: user.email,
      password: user.password
    }
    return this.http.post(Urlconstants.auth.loadOSAdminLogin, userSignInRequest);
  }
  getAccessTokenByRefresh() {
    var loginUser = JSON.parse(localStorage.getItem('loginUser'));
    var refreshToken = loginUser.refreshToken;
    return this.http.post(Urlconstants.auth.getAccessTokenByRefresh + "?refreshToken=" + refreshToken, null);
  }

}
