import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import fileSaver, * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { dataConstant } from './dataConstant';
import { HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})

export class CommonService {

  public pageHeader: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private _toastr: ToastrService,
    public spinner: NgxSpinnerService) {
  }
  public toastSuccessMsg(title: any, message: any, timeOut?: object) {
    this._toastr.success(message, title, timeOut);
  }

  public toastWarningMsg(title: any, message: any, timeOut?: object) {
    this._toastr.warning(message, title, timeOut);
  }

  public toastErrorMsg(title: any, message: any, timeOut?: object) {
    this._toastr.error(message, title, { timeOut: timeOut == null ? 5000 : Number(timeOut), enableHtml: true });
  }

  //show loader
  public showLoading() {
    this.spinner.show();
  }

  //Hide loader
  public hideLoading() {
    setTimeout(() => {
      this.spinner.hide();
    }, 900);
  }
  addDays = (date, days = 1) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  dateRange = (start, end, range = []) => {
    if (start > end) return range;
    const next = this.addDays(start, 1);
    return this.dateRange(next, end, [...range, start]);
  };

  getDatesInRange(startDate, endDate) {
    const date = new Date(startDate);

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date).getTime());
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  public formatDateToString(selectedDate: any) {
    var date = new Date(selectedDate)
    var formattedDate = ""

    if (date != null && date != undefined) {
      var month = date.getMonth() + 1;
      var formattedDate = (date.getFullYear() + "-") +
        (month >= 10 ? month + "-" : "0" + month + "-") +
        (date.getDate() >= 10 ? date.getDate() : "0" + date.getDate());

    }
    return formattedDate;
  }

  public formatStringToDate(date: any) {
    var formattedDate = {};
    if (date != '' && date != undefined) {
      formattedDate = {
        "year": new Date(date).getFullYear(),
        "month": new Date(date).getUTCMonth() + 1,
        "day": new Date(date).getUTCDate()
      };

    } else {
      formattedDate = "";
    }
    return formattedDate;
  }
  public timeFormat(time: any) {
    var hr = time.hour;
    var min = time.minute;
    if (time != null) {
      var formattedTime = (hr >= 10 ? hr + ":" : "0" + hr + ":") +
        (min >= 10 ? min + ":" : "0" + min);
      return formattedTime;
    }
    return time;
  }
  public bsDateFormat(date: any) {
    var year: number = date.getFullYear();
    var month: number = date.getMonth() + 1;
    var day: number = date.getDate();
    var weekday = date.getDay();
    if (date != null && date != "") {
      var formattedDate = (year + "-") +
        (month >= 10 ? month + "-" : "0" + month + "-") +
        (day >= 10 ? day : "0" + day);
    }
    return formattedDate;
  }
  public dateFormatMdDdYyyy(date: any) {
    var year: number = date.getFullYear();
    var month: number = date.getMonth() + 1;
    var day: number = date.getDate();
    if (date != null && date != "") {
      var formattedDate = (month >= 10 ? month : "0" + month) + "-" +
        (day >= 10 ? day : "0" + day) + "-" + (year);
    }
    return formattedDate;
  }

  public bsDateFormatForLong(date: any) {
    var daformatedDatete = new Date(date);
    return daformatedDatete.getTime();
  }
  setToZeroTime(date: any) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }
  setToEndTime(date: any) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }
  public bsDateFormatForDate(time: any) {
    var date = new Date(time);
    var formatedDate = this.formatDateToString(date);
    return formatedDate;
  }
  toUTC(date: Date) {
    return new Date(date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds());
  }
  getDefaultTimeZone() {
    // var timeZ = new Date().toTimeString().slice(9);
    // var temp = timeZ.replace("GMT+", "").split(" ")[0];
    // const result = temp.slice(0, 2) + ":" + temp.slice(2);
    // var timeZoneList = dataConstant.timeZoneList;
    // var temp1 = timeZoneList.filter(e => e.id.includes(result));
    // return temp1[0].id;

    // { id: "(GMT-08:00) Pacific Time (US and Canada)", text: "(GMT-08:00) Pacific Time (US and Canada)" },
    return "(GMT-08:00) Pacific Time (US and Canada)";

  }
  UTCtoLocalDate(date: any) {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }
  public dateFormat(date: any) {
    var year: number = date.getFullYear();
    var month: number = date.getMonth() + 1;
    var day: number = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    var weekday = date.getDay();
    switch (weekday) {
      case 1:
        weekday = 'Mon';
        break;
      case 2:
        weekday = 'Tue';
        break;
      case 3:
        weekday = 'Wed';
        break;
      case 4:
        weekday = 'Thu';
        break;
      case 5:
        weekday = 'Fri';
        break;
      case 6:
        weekday = 'Sat';
        break;
      case 7:
        weekday = 'Sun';
        break;
    }

    if (date != null && date != "") {
      var formattedDate = (year + "-") +
        (month >= 10 ? month + "-" : "0" + month + "-") +
        (day >= 10 ? day : "0" + day) +
        " " +
        (hour >= 10 ? hour : "0" + hour) + ":" +
        (minute >= 10 ? minute : "0" + minute) + ":" +
        (second >= 10 ? second : "0" + second)
        ;
    }
    return formattedDate;
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    worksheet['!cols'] = json;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0))
    }
    ));
  }

  async saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  saveAsEDIFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {});
    FileSaver.saveAs(data, '204_' + fileName + ".edi");
  }

  saveAsCSVFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: 'text/csv' });
    FileSaver.saveAs(data, filename + ".csv");
  }

  saveAsExcelFormatFile(buffer: any, fileName: string) {
    var date = new Date();
    var filename = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    const data: Blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
    FileSaver.saveAs(data, filename + ".xlsx");
  }

  downloadFile(url: any, fileName: any) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", fileName);
        a.click();
      }
      );
    });
  }
  changePageHeader(pageHeader: string) {
    this.pageHeader.next(pageHeader);
  }
  writeToCSV(data, header, fileName) {
    var options = {
      headers: header
    };
    var date = new Date();
    fileName = fileName + date.getFullYear() + date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + date.getUTCMinutes();
    new ngxCsv(data, fileName, options);
  }
  toTitleCase(stringVal) {
    return stringVal.charAt(0).toUpperCase() + stringVal.substr(1).toLowerCase();
  }
  inputToTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  getHeader(compId) {
    const headers = new HttpHeaders()
      .set(Constants.HEADER_COMP_KEY + "", compId + "")
    return headers;
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
  addHyphenToMobile(mobileNumber, isPastedData) {
    if (mobileNumber != null && mobileNumber != undefined && mobileNumber != '') {
      var count = mobileNumber.replaceAll(" ", "").split("-").length - 1;
      if (count <= 1) {
        if (mobileNumber != null) {
          var temp = mobileNumber.replaceAll(" ", "").split('-').join('');
          if (temp != null) {
            let finalVal = temp.match(/.{1,3}/g).join('-');
            //if (isPastedData) {
            const lastIndexOfL = finalVal.lastIndexOf('-');
            const removeLastL = finalVal.slice(0, lastIndexOfL) +
              finalVal.slice(lastIndexOfL + 1);
            mobileNumber = removeLastL;
            // }
            // else {
            //   mobileNumber = finalVal;
            // }
          }
        }
      }
    }
    //lastIndexOf()
    return mobileNumber;
  }
}



