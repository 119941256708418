import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import "rxjs";
import { catchError, map, finalize, timeout } from 'rxjs/operators';
import { Router } from "@angular/router";
import { LoginService } from '../login/login.service';
import { CommonService } from '../common/commonService';
import { BillingService } from '../all-module/billing/billing.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  response: any = {};
  i = 0;
  prevExpireTime = 0;
  constructor(private _router: Router,
    private billingService: BillingService,
    private commonService: CommonService,
    private loginService: LoginService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // add authorization header with jwt token if available
    let loginUser: any = localStorage.getItem('loginUser');
    var currentUser = JSON.parse(loginUser);
    if (currentUser) {
      //this.getCurrentPlan();
      // Get new token using refresh token
      if (currentUser.loginTime != undefined && currentUser.loginTime != null) {
        var diff = new Date().getTime() - currentUser.loginTime;
        const seconds = Math.floor(diff / 1000);
        var expiresIn = currentUser.expiresIn;
        var expireTime = expiresIn - seconds;
        if (expireTime < 120 && this.prevExpireTime != expireTime) {
          setTimeout(() => {
            // this.getAccessTokenByRefresh();
          }, 2000);
        }
        this.prevExpireTime = expireTime;
      }
      request = request.clone({ headers: request.headers.set('Authorization', `${currentUser.token}`) });
      if (!request.url.includes('/auth/refresh') && !request.url.includes('/company/create') && !request.url.includes('/tracking/company')) {
        request = request.clone({ headers: request.headers.set('cId', `${currentUser.companyId}`) });
        //this.getCurrentPlan();
      }


    }
    if (!request.headers.has('content-type')) {
      request.headers.set('content-type', 'application/json');
      request.headers.set('accept', 'application/json, text/plain, */*');
    }

    return next.handle(request).pipe(map(event => {
      return event;
    }), catchError(err => {
      if (err.status === 401) {
        localStorage.removeItem('loginUser');
        this._router.navigate(['/login']);
      }
      if (err.status === 400) {
        if (err.error.apierror != undefined && err.error.apierror != null) {
          if (err.error.apierror.description.includes('Access Token')) {
            localStorage.removeItem('loginUser');
            this._router.navigate(['/login']);
          }
          else if (err.error.apierror.description.includes('Your subscription expired')) {
            var loginUser = JSON.parse(localStorage.getItem("loginUser"));
            loginUser.currentPlan = "EXPIRED";
            localStorage.setItem("loginUser", JSON.stringify(loginUser));
            this.commonService.toastErrorMsg("Attention Required", "Expired: 15 Day Trial", null)
            this._router.navigate(['/billing']);
          }
          else if (err.error.apierror.description.includes('parameter coupon_ids[0] is not present')) {
            this.commonService.toastWarningMsg("Warning", "Please enter valid coupon", null)
          }
          if (request.url.includes('/customer/import') || request.url.includes('/user/import') || request.url.includes('/truck/import') || request.url.includes('/trailer/import')) {
            // window.location.reload();
          }
        }
      }
      if (err.status === 409) {
        if (err.error.apierror != undefined && err.error.apierror != null) {
          this.commonService.hideLoading();
          this.commonService.toastErrorMsg("Error", err.error.apierror.description, null);
        }
        if (request.url.includes('/customer/import') || request.url.includes('/user/import') || request.url.includes('/truck/import') || request.url.includes('/trailer/import')) {
          // window.location.reload();
        }
      }
      else if (err.status === 500) {
        if (request.url.includes('/customer/import') || request.url.includes('/user/import') || request.url.includes('/truck/import') || request.url.includes('/trailer/import')) {
          // window.location.reload();
        }
        // this.commonService.toastErrorMsg("Error", "Something went wrong", null);
      }
      else
        return throwError(err);
    }), finalize(() => {
    })
    );
  }
  getAccessTokenByRefresh() {
    let loginUser: any = localStorage.getItem('loginUser');
    var currentUser = JSON.parse(loginUser);
    if (currentUser.loginTime != undefined && currentUser.loginTime != null) {
      var diff = new Date().getTime() - currentUser.loginTime;
      const seconds = Math.floor(diff / 1000);
      var expiresIn = currentUser.expiresIn;
      var expireTime = expiresIn - seconds;
      if (expireTime < 120) {
        this.commonService.showLoading();
        this.i++;
        this.loginService.getAccessTokenByRefresh().subscribe({
          next: (response) => {
            this.response = response;
            var loginUser = JSON.parse(localStorage.getItem('loginUser'));
            if (loginUser != undefined && loginUser != null) {
              loginUser.token = "Bearer" + " " + this.response.apiresponse.data.accessToken;
              loginUser.expiresIn = this.response.apiresponse.data.expiresIn;
              localStorage.setItem("loginUser", JSON.stringify(loginUser));
            }
            this.commonService.hideLoading();
          },
          error: (err: any) => {
            this.commonService.hideLoading();
            if (err.error.apierror != undefined && err.error.apierror != null) {
              this.commonService.toastErrorMsg("Error", err.error.apierror.description.null);
            }
          },
          complete: () => {
          }
        });

      }
    }
  }
  getCurrentPlan() {
    this.billingService.getPlan().subscribe({
      next: (response) => {
        this.response = response;
        var planActivateDate = this.response.apiresponse.data.activatedAt;
        var planExpireDate = this.response.apiresponse.data.expiredAt;
        if (planActivateDate != 0) {
          if (planExpireDate < new Date().getTime()) {
            this._router.navigate(['/billing']);
          }
        }
      },
      error: (err: any) => {
        this.commonService.hideLoading();
        if (err.error.apierror != undefined && err.error.apierror != null) {
          this.commonService.toastErrorMsg("Error", err.error.apierror.description, null);
        }
      },
      complete: () => {
      }
    });
  }
}
