import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urlconstants } from 'src/app/common/urlconstant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) { }
  getPlan() {
    return this.http.get(Urlconstants.subscription.getPlan);
  }
  purchasePlan(plan) {
    return this.http.post(Urlconstants.subscription.purchasePlan, plan);
  }
  getChargebeeDetail(companyId) {
    return this.http.get(Urlconstants.subscription.getChargebeeDetail + companyId);
  }
  getHostedPageURL(subId, adminPlanId, adminCount, mobilePlanId, mobileCount, adminRate, driverRate, couponId) {
    var payLoad = {
      "itemId1": adminPlanId,
      "itemId2": mobilePlanId,
      "quantity1": adminCount,
      "quantity2": mobileCount,
      "subscriptionId": subId,
      "unitPrice1": adminRate,
      "unitPrice2": driverRate,
      "couponName": couponId
    }
    return this.http.post(Urlconstants.subscription.getHostedPageURL, payLoad)
  }
  getInvoiceURL(invoiceId) {
    return this.http.get(Urlconstants.subscription.getInvoice + invoiceId);
  }
  getPlanList() {
    return this.http.get(Urlconstants.subscription.getPlanList);
  }
  cancelSubscription(subscriptionId, companyId) {
    return this.http.post(Urlconstants.subscription.cancelSubscription + "?subscriptionId=" + subscriptionId + "&companyId=" + companyId, null);
  }
}
