import { environment } from '../../environments/environment';

export const Urlconstants = {
  auth: {
    login: environment.APIURL + "/auth/sign-in",
    signUp: environment.APIURL + "/auth/sign-up",
    loadOSAdminLogin: environment.APIURL + "/auth/loadOsAdmin/sign-in",
    getAccessTokenByRefresh: environment.APIURL + "/auth/refresh"
  },
  company: {
    create: environment.APIURL + "/company/create",
    update: environment.APIURL + "/company/update",
    get: environment.APIURL + "/company/",
    getCompanyUserDetail: environment.APIURL + "/company/user"
  },
  truck: {
    create: environment.APIURL + "/truck/create",
    update: environment.APIURL + "/truck/update",
    get: environment.APIURL + "/truck/company/",
    getById: environment.APIURL + "/truck/",
    getAll: environment.APIURL + "/truck/company",
    delete: environment.APIURL + "/deactivate/trucks",
    importCSV: environment.APIURL + "/truck/import"
  },
  trailer: {
    create: environment.APIURL + "/trailer/create",
    update: environment.APIURL + "/trailer/update",
    get: environment.APIURL + "/trailer/company/",
    getById: environment.APIURL + "/trailer/",
    getAll: environment.APIURL + "/trailer/company",
    delete: environment.APIURL + "/deactivate/trailers",
    importCSV: environment.APIURL + "/trailer/import"
  },
  driver: {
    get: environment.APIURL + "/driver/company/",
    create: environment.APIURL + "/driver/create"
  },
  department: {
    getAll: environment.APIURL + "/department/",
    create: environment.APIURL + "/department/create"
  },
  user: {
    getAll: environment.APIURL + "/user/",
    getAllFiltered: environment.APIURL + "/user/company/",
    create: environment.APIURL + "/user/create",
    update: environment.APIURL + "/user/update/",
    get: environment.APIURL + "/user/",
    forgotPassword: environment.APIURL + "/user/forgotPassword",
    forgotVerify: environment.APIURL + "/user/confirmForgotPassword",
    getByRole: environment.APIURL + "/user/role",
    importCSV: environment.APIURL + "/user/import",
    deactivate: environment.APIURL + "/deactivate/users",
    sendText: environment.APIURL + "/user/sendTexts"
  },
  customer: {
    create: environment.APIURL + "/customer/create",
    update: environment.APIURL + "/customer/update",
    get: environment.APIURL + "/customer/company/",
    getDetail: environment.APIURL + "/customer/",
    getAll: environment.APIURL + "/customer/company",
    importCSV: environment.APIURL + "/customer/import",
    delete: environment.APIURL + "/deactivate/customers"
  },
  load: {
    create: environment.APIURL + "/load/create",
    update: environment.APIURL + "/load/update",
    get: environment.APIURL + "/load/",
    status: environment.APIURL + "/load/status",
    getLoadNumberList: environment.APIURL + "/load/loadNumbers/company/"
  },
  loadBoard: {
    search: environment.APIURL + "/loadboard/search",
    getLoadDetail: environment.APIURL + "/loadboard/"
  },
  stop: {
    save: environment.APIURL + "/stop/load/",
    getAllLocation: environment.APIURL + "/stop/load/company/"
  },
  docs: {
    getAllDocs: environment.APIURL + "/document/load/",
    deleteDocs: environment.APIURL + "/document/load/",
    createDocs: environment.APIURL + "/document/load/",
    updateStatus: environment.APIURL + "/document/load/status"
  },
  charge: {
    getAllCharge: environment.APIURL + "/charge/load/",
    deleteCharge: environment.APIURL + "/charge/load/",
    createCharge: environment.APIURL + "/charge/load/",
    updateStatus: environment.APIURL + "/charge/load/status"
  },
  schedule: {
    getAllTruckSchedule: environment.APIURL + "/schedule/truck",
    getScheduleByTruck: environment.APIURL + "/schedule/load/truck/",
    getTruckAvailibility: environment.APIURL + "/schedule/truck/",//{truckId}/availability,
    getDriverAvailability: environment.APIURL + "/schedule/driver/availability",
    createEntityAvailability: environment.APIURL + "/schedule/entity/availability",
    deleteEntityAvailability: environment.APIURL + "/schedule/entity/availability",
    updateEntityAvailability: environment.APIURL + "/schedule/entity/availability/",//{id},
    getTruckSlot: environment.APIURL + "/schedule/slots/trucks/company/" //{companyId}
  },
  maintenance: {
    createMaintenance: environment.APIURL + "/maintenance/create",
    updateMaintenance: environment.APIURL + "/maintenance/update",
    deleteMaintenance: environment.APIURL + "/maintenance/", // {maintenanceId}
    getAllMaintenance: environment.APIURL + "/maintenance/}"
  },
  analytics: {
    getReport: environment.APIURL + "/analytics/",
    getCustomerDetail: environment.APIURL + "/analytics/customers",
    getRevenueDetail: environment.APIURL + "/analytics/revenue",
    getTruckDetail: environment.APIURL + "/analytics/trucks",
    getDispatcherDetail: environment.APIURL + "/analytics/dispatchers",
    getExpenseDetail: environment.APIURL + "/analytics/expenses",
    getFuelDetail: environment.APIURL + "/analytics/fuels",
    getIFTA: environment.APIURL + "/analytics/ifta"
  },
  expense: {
    addExpense: environment.APIURL + "/expense/",
    getExpenseDetail: environment.APIURL + "/expense/get/",//{expenseId}
    updateExpense: environment.APIURL + "/expense/"//{expenseId}"
  },
  chat: {
    getChatDetail: environment.APIURL + "/chat/user",
    getChatHistory: environment.APIURL + "/messages/",//roomId,
    addMember: environment.APIURL + "/chat/room/addMember",
    getRoomMember: environment.APIURL + "/chat/room/", //room number
    setCheckOutTime: environment.APIURL + "/group/"//{roomJid}/user/{userId}

  },
  stopLocation: {
    create: environment.APIURL + "/stop/location/create",//stopLocationRequest
    update: environment.APIURL + "/stop/location/",//{stopLocationId} & stopLocationRequest
    getLocation: environment.APIURL + "/stop/location/",//{stopLocationId}/company/{companyId}"
    getAll: environment.APIURL + "/stop/location/company/"//{companyId}"
  },
  ExternalMap: {
    getExternalMapCoords: environment.APIURL + "/tracking/company/"//{companyId}/externalMap"
  },
  subscription: {
    getPlan: environment.APIURL + "/subscription/latest/company",//{companyId}
    purchasePlan: environment.APIURL + "/subscription/purchase",
    getChargebeeDetail: environment.APIURL + "/chargebee/company/",//{companyId}"
    getHostedPageURL: environment.APIURL + "/subscription/checkout/existing",
    cancelSubscription: environment.APIURL + "/subscription/cancelled",
    getInvoice: environment.APIURL + "/subscription/invoice/",//{invoiceId}
    getPlanList: environment.APIURL + "/subscription/plan"
  },
  video: {
    getList: environment.APIURL + "/admin/company/videos/all",
    getByType: environment.APIURL + "/admin/company/videos/",//{{type}}
  }
}
