import { Component, OnInit } from '@angular/core';
import { AngularFaviconService } from 'angular-favicon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  title = 'CargoHQ';
  constructor(private ngxFavicon: AngularFaviconService) { }

  ngOnInit() {
    this.ngxFavicon.setFavicon("./assets/images/brandlogo.png");
  }
}
