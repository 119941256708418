import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let loginUser: any = localStorage.getItem('loginUser');
    var currentUser = JSON.parse(loginUser);
    if (currentUser != null && currentUser != undefined) {
      return true;
    }
    this.router.navigate(['/login']);
    return true;
  }
}
